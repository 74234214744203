
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NB_AUTH_OPTIONS, NbAuthSocialLink } from '../../auth.options';
import { getDeepFromObject } from '../../helpers';

import { NbAuthService } from '../../services/auth.service';
import { NbAuthResult } from '../../services/auth-result';

@Component({
  selector: 'nb-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NbLoginComponent implements OnInit {

  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = '';
  pattern:string = '.+';
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted: boolean = false;
  socialLinks: NbAuthSocialLink[] = [];
  rememberMe = false;

  constructor(protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router) {

    this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
    this.showMessages = this.getConfigValue('forms.login.showMessages');
    this.strategy = this.getConfigValue('forms.login.strategy');
    this.socialLinks = this.getConfigValue('forms.login.socialLinks');
    this.rememberMe = this.getConfigValue('forms.login.rememberMe');
    //this.pattern ='.+@.+\..+';
  }

  ngOnInit(): void {
    // Prevent user for accessing login page if user is already authenticated.
    this.preventLoginIfAuthenticated();
  }

  login(): void {
    this.errors = [];
    this.messages = [];
    this.submitted = true;

    this.service.authenticate(this.strategy, this.user).subscribe((result: NbAuthResult) => {
      //this.submitted = false;
     // console.log(result);
      if (result.isSuccess()) {
        this.messages = result.getMessages();
      } else {
        this.errors = result.getErrors();
        this.submitted = false;
      }

      const redirect = result.getRedirect();
      this.redirectToUrl(redirect);
    });
  }

  redirectToUrl(redirectUrl: string) {
    if (redirectUrl) {
      console.log('Navigating to page ' + redirectUrl + "after " + this.redirectDelay + "sec");
      setTimeout(() => {
        return this.router.navigateByUrl(redirectUrl);
      }, this.redirectDelay);
    }
    this.cd.detectChanges();
  }

  preventLoginIfAuthenticated() {
    this.service.isAuthenticated().subscribe(result => {
      if (result) {
        const loginSuccessUrl = this.options?.["strategies"]?.[0]?.[1]?.login?.redirect?.success;
        if (loginSuccessUrl) {

          this.redirectToUrl(loginSuccessUrl);
        }
      }
    })
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
