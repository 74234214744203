
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NB_AUTH_OPTIONS } from '../../auth.options';
import { getDeepFromObject } from '../../helpers';

import { NbAuthService } from '../../services/auth.service';
import { NbAuthResult } from '../../services/auth-result';
import { decodeJwtPayload } from '../../services/token/token';

@Component({
  selector: 'nb-reset-password-page',
  styleUrls: ['./reset-password.component.scss'],
  templateUrl: './reset-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NbResetPasswordComponent {

  redirectDelay: number = 100;
  showMessages: any = {};
  strategy: string = '';

  submitted = false;
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  email: string;
  token: string;

  constructor(protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,private route: ActivatedRoute,
    protected router: Router) {

    this.redirectDelay = this.getConfigValue('forms.resetPassword.redirectDelay');
    this.showMessages = this.getConfigValue('forms.resetPassword.showMessages');
    this.strategy = this.getConfigValue('forms.resetPassword.strategy');
  }

  getTokenExpDate(token: any): Date {
    // console.log(token.iat);
    // console.log(token.exp);

    // console.log(new Date(Number(token.iat) * 1000));
    // console.log(new Date(Number(token.exp) * 1000));
    if (!token.hasOwnProperty('exp')) {
      return null;
    }
    // return new Date(new Date(Number(token.iat) * 1000).getTime() + Number(token.exp) * 1000);
    return new Date(Number(token.exp) * 1000);
  }
  isTokenValid: boolean = false;

  ngOnInit() {
    try {
      this.route.queryParams.subscribe(params => {
        console.log(params);
        // this.invoiceId = params['id'];
        // console.log(this.invoiceId);
        // this.GetInvoiceWithProvidedId();
        // this.getFolderSummary();
        this.email = params['email'];
        // let token: NbAuthJWTToken = params['token'];
        this.token = params['token'];
        let tokenParsed: any = decodeJwtPayload(params['token']);
        console.log(tokenParsed);
        // new NbAuthJWTToken()
        if (tokenParsed) {
          console.log(this.getTokenExpDate(tokenParsed));
          console.log(new Date());
          this.isTokenValid = new Date() > this.getTokenExpDate(tokenParsed);
          //if (token.isValid()) {
          // Receive a payload from the token and assigns it to our `user` variable.
          //let user = token.getPayload();
          //console.log(user);
          // this.userRoles = user.role;
          // console.log('Current user Rols are', this.userRoles);
        }
        else {
          this.submitted = true;
          this.errors = this.messages = ["Reset Link Is Invalid or Has Expired"];
          const redirect = "/";
          setTimeout(() => {
            return this.router.navigateByUrl(redirect);
          }, this.redirectDelay * 2);
        }

      });

      if (this.isTokenValid)
        throw Error("Expired Token");
      // this.route.params.subscribe(params => {
      //   console.log(params);
      //   // this.invoiceId = params['id'];
      //   // console.log(this.invoiceId);
      //   // this.GetInvoiceWithProvidedId();
      //   // this.getFolderSummary();
      // });
    } catch (error) {
      console.log("Fatal Error");
      this.submitted = true;
      this.errors = this.messages = ["Reset Link Is Invalid or Has Expired"];
      const redirect = "/";
      this.router.navigateByUrl(redirect);
      // setTimeout(() => {
      //   return this.router.navigateByUrl(redirect);
      // }, this.redirectDelay);
    }
  }

  resetPass(): void {
    this.errors = this.messages = [];
    this.submitted = true;
    this.user.email = this.email;
    this.user.token = this.token;
    this.service.resetPassword(this.strategy, this.user).subscribe((result: NbAuthResult) => {
      this.submitted = false;
      if (result.isSuccess()) {
        this.messages = result.getMessages();
      } else {
        this.errors = result.getErrors();
      }

      const redirect = result.getRedirect();
      if (redirect) {
        setTimeout(() => {
          return this.router.navigateByUrl(redirect);
        }, this.redirectDelay);
      }
      this.cd.detectChanges();
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
