import { Injectable, Injector } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {

    // constructor(private authService: NbAuthService, private router: Router) {
    //     console.log('Curr Auth service instance', authService);
    // }

    // constructor(private injector: Injector, private router: Router) {
    // }

    canActivate() {
        // return this.authService.isAuthenticated()
        //     .pipe(
        //         tap(authenticated => {
        //             if (!authenticated) {
        //                 this.router.navigate(['auth/login']);
        //             }
        //         }),
        //     );
        return true;
    }

    // protected get authService(): NbAuthService {
    //     return this.injector.get(NbAuthService);
    // }
}
