import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ShipmentRequest } from '../../components/dialogs/so-create-dialog/shipment-request.model';
import { AsnRequest } from '../../components/dialogs/asn-create-dialog/asn-request.model';
// import { Cart } from './request-cart.model';
import { filter, share } from 'rxjs/operators';
import { Cart } from './request-cart.model';
import { UserCart } from './user-cart.model';
import { NbAuthService, NbAuthJWTToken } from '../../../@auth/services';

@Injectable({
  providedIn: 'root'
})
export class RequestCartService {

 // protected shipments$: BehaviorSubject<ShipmentRequest> = new BehaviorSubject(null);
 // protected asns$: BehaviorSubject<AsnRequest> = new BehaviorSubject(null);
  protected cart$: BehaviorSubject<Cart> = new BehaviorSubject(null);
  protected userCarts$: BehaviorSubject<UserCart[]> = new BehaviorSubject(null);

  public _asns :AsnRequest[] = [];
  public _shipments : AsnRequest[] =[];
 // protected _null$ :  BehaviorSubject<any> = new BehaviorSubject(null);
  protected key:string = "RequestCart";
  protected user:any;

  constructor(private authService: NbAuthService) {
    this.authService.onTokenChange()
    .subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        // Receive a payload from the token and assigns it to our `user` variable.
        this.user = token.getPayload();
        //console.log('Current user is', this.user);
        console.log('Current user Obtained');
        // set default image
        //this.user.picture = "../../../../assets/images/user-gray.jfif";
        //this.company = "@" + this.user.customerId;
        this.publishCart();
      }
    });
   }

      /**
   * Publishes Cart when Items changes.
   * @returns {Observable<NbAuthToken>}
   */
  cartChange(): Observable<Cart> {
    return this.cart$
      .pipe(
        filter(value => !!value),
        share(),
      );
  }

  set(cart: Cart): Observable<null> {
    //  console.log("Setting Token" + JSON.stringify(token));

      //this.tokenStorage.set(token);
      this.setCartItems(cart);
      this.publishCart();
      return this.observableOf(null);
    }
  
    get(): Observable<Cart> {
      const cart = this.getCartItems();
      return this.observableOf(cart);
    }
  
    clear(): Observable<null> {
      localStorage.removeItem(this.key);
      this.publishCart();
      return this.observableOf(null);
    }

    protected publishCart() {
      //this.cart$.next(this.tokenStorage.get());
      this.cart$.next(this.getCartItems());
      console.log("Published Cart State : " + JSON.stringify(this.getCartItems()));
    }

    private observableOf(data:any): Observable<null>
    {
        return of<null>(null);
    }

    private getCartItems():Cart
    {
     // let cart:Cart = JSON.parse(localStorage.getItem(this.key));

      let cart:Cart = null;
      let carts:UserCart[] = this.GetUsersCarts();
      let userId:string = this.user.unique_name;
     // console.log(this.user);
      console.log(carts);

      if(carts){
         
          if(carts.filter(x=>x.userId == userId).length == 0)
            return null;
          let userCart:UserCart = carts.filter(x=>x.userId == userId)[0] ; 
          return { shipments :userCart.shipments , asns:userCart.asns };
      }

      return cart;
    }

    private GetUsersCarts():UserCart[]
    {
       let carts:UserCart[] = JSON.parse(localStorage.getItem(this.key));
       return carts;
    }

    private SetUsersCarts(carts):void
    {
      //localStorage.setItem(this.key,JSON.stringify(cart));
      localStorage.setItem(this.key,JSON.stringify(carts));
    }

    private setCartItems(cart:Cart):void
    {
      //localStorage.setItem(this.key,JSON.stringify(cart));
      let carts:UserCart[] = this.GetUsersCarts();
      let userId:string = this.user.unique_name;

      if(cart){
          if(carts)
          {
            
            let index:number = carts.indexOf(carts.filter(x=>x.userId == userId)[0]);
            console.log("Index "+ index + " - Cart : " + JSON.stringify(cart));
            if(index > -1)
            {
              carts.splice(index,1);
            }
            carts = [...carts,{shipments:cart.shipments,asns:cart.asns,userId:userId}];
          }
          else
          {
            carts =[{shipments:cart.shipments,asns:cart.asns,userId:userId}]
          }
      }

      this.SetUsersCarts(carts);
    }
}
