import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NB_WINDOW } from '@nebular/theme';
//import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { NbAuthJWTToken, NbAuthService } from '../../../@auth/services/';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { RippleService } from '../../../@core/utils/ripple.service';
import { Router } from '@angular/router';
import { RequestCartService } from '../../../shared/services/cart/request-cart.service';
import { Cart } from '../../../shared/services/cart/request-cart.model';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  public readonly materialTheme$: Observable<boolean>;
  userPictureOnly: boolean = false;
  user: any;
  company: string = "@GwC"

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
    {
      value: 'material-light',
      name: 'GWC Sea Green Light',
    },
    {
      value: 'material-dark',
      name: 'GWC Kelly Green Dark',
    },
    // {
    //   value: 'gwc-kelly-green-dark',
    //   name: 'GWC Kelly Green Dark',
    // },
    // {
    //   value: 'gwc-sea-green-default',
    //   name: 'GWC Sea Green Light',
    // }
  ];

  currentTheme = 'default';

  userMenu = [
    {
      title: 'Profile',
      link: '/pages/users/profile',
      icon: 'person-outline'
    },
    {
      title: 'Log out',
      link: '/auth/logout',
      icon: 'unlock-outline'
    }
  ];

  cart:Cart;
  itemCount:string;

  public constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private rippleService: RippleService,
    private authService: NbAuthService,
    private requestCartService: RequestCartService,
    private nbMenuService: NbMenuService,
    @Inject(NB_WINDOW) private window,
    private router: Router) 
    {
    
      this.materialTheme$ = this.themeService.onThemeChange()
      .pipe(map(theme => {
        const themeName: string = theme?.name || '';
        return themeName.startsWith('material');
      }));

    // subscribe to an onTokenChange method, which will push an event on each token update so that
    // the header component is up - to - date with the information received from the backend and
    // there is no need to additionally refresh / request the data:
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {

        if (token.isValid()) {
          // Receive a payload from the token and assigns it to our `user` variable.
          this.user = token.getPayload();
          //console.log('Current user is', this.user);
          console.log('Current user Obtained');
          // set default image
          this.user.picture = "../../../../assets/images/user-gray.jfif";
          this.company = "@";
          //this.company = "@" + this.user.customerId;
        }
      });

    this.requestCartService.cartChange().subscribe((cart: Cart) => {
      console.log('Current user Cart Obtained');
        this.cart = cart;
        this.itemCount = this.cart == null ? "0" : (this.cart.shipments.length + this.cart.asns.length).toString();
    });

    //Handle menu item click
    // this.nbMenuService.onItemClick()
    //   .pipe(
    //     filter(({ tag }) => tag === 'user-context-menu'),
    //     map(({ item: { title } }) => title),
    //   )
    //   .subscribe(title => {
    //     if (title === 'Log out') {
    //       this.router.navigate(['/auth/logout']);
    //       this.window.alert(`${title} was clicked!`)
    //     }
    //   });
  }


  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => this.user = users.maged);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => {
        this.currentTheme = themeName;
        this.rippleService.toggle(themeName?.startsWith('material'));
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateToCart()
  {
    this.router.navigateByUrl("/pages/cart/all")
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  onContecxtItemSelection(title) {
    if (title === 'Log out') {
      console.log('logout context menu selected.');
      this.router.navigate(['/auth/logout']);
    }
  }
}
