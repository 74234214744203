import { NgModule } from '@angular/core';
import { ThemeModule } from 'app/@theme/theme.module';

export const COMPONENTS = [
    //PagedListResponse
];

@NgModule({
    imports: [
        ThemeModule
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS
})
export class SharedModule { }
