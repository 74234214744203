<div class="header-container">
  <div class="logo-container">
    <a href="#" class="sidebar-toggle" matRipple [matRippleUnbounded]="true" [matRippleCentered]="true"
      (click)="toggleSidebar()">
      <nb-icon [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"></nb-icon>
    </a>
    <!-- <a class="logo" href="#" (click)="navigateHome()">ngx-<span>admin</span></a> -->
    <a class="logo" href="#" (click)="navigateHome()">
      <span>
        <img src="../../../../assets/images/gwc-logo.png" height="49" width="100">
      </span>
    </a>
  </div>
  <!-- <i class="fas fa-palette"></i> -->
  <!-- <nb-select status="primary" matRipple [selected]="currentTheme" (selectedChange)="changeTheme($event)">
    <nb-option *ngFor="let theme of themes" [value]="theme.value" matRipple>{{ theme.name }}</nb-option>
  </nb-select> -->
</div>


<!-- <div class="header-container">
  <h2 class="header-title">GWC Eagle Portal</h2>
</div> -->

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="control-item">
      <nb-search type="rotate-layout" matRipple [matRippleUnbounded]="true" [matRippleCentered]="true"></nb-search>
    </nb-action>
    <!-- <nb-action class="control-item" icon="email-outline" matRipple [matRippleUnbounded]="true"
      [matRippleCentered]="true"></nb-action>
    <nb-action class="control-item" icon="bell-outline" matRipple [matRippleUnbounded]="true"
      [matRippleCentered]="true"></nb-action> -->
    <nb-action class="control-item" icon="shopping-cart-outline" (click)="navigateToCart()" matRipple [matRippleUnbounded]="true" badgeText="{{itemCount}}"
      [matRippleCentered]="true"></nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" matRipple [matRippleUnbounded]="true"
      [matRippleCentered]="true"> 
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.unique_name" [title]="company"
        nbContextMenuTag="user-context-menu" [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>