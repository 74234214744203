import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
//import { NbAuthJWTToken, NbAuthModule, NbDummyAuthStrategy, NbPasswordAuthStrategy } from '@nebular/auth';
import { NbAuthModule } from '../@auth/auth.module';
import { NbAuthJWTToken } from '../@auth/services/token/token';
import { NbDummyAuthStrategy, NbPasswordAuthStrategy, NbOAuth2AuthStrategy, NbAuthStrategyOptions } from '../@auth/strategies/'

import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
  PlayerService,
  SeoService,
  StateService,
} from './utils';

import { UserData } from './data/users';
import { ElectricityData } from './data/electricity';
import { SmartTableData } from './data/smart-table';
import { UserActivityData } from './data/user-activity';
import { OrdersChartData } from './data/orders-chart';
import { ProfitChartData } from './data/profit-chart';
import { TrafficListData } from './data/traffic-list';
import { EarningData } from './data/earning';
import { OrdersProfitChartData } from './data/orders-profit-chart';
import { TrafficBarData } from './data/traffic-bar';
import { ProfitBarAnimationChartData } from './data/profit-bar-animation-chart';
import { TemperatureHumidityData } from './data/temperature-humidity';
import { SolarData } from './data/solar';
import { TrafficChartData } from './data/traffic-chart';
import { StatsBarData } from './data/stats-bar';
import { CountryOrderData } from './data/country-order';
import { StatsProgressBarData } from './data/stats-progress-bar';
import { VisitorsAnalyticsData } from './data/visitors-analytics';
import { SecurityCamerasData } from './data/security-cameras';

import { UserService } from './mock/users.service';
import { ElectricityService } from './mock/electricity.service';
import { SmartTableService } from './mock/smart-table.service';
import { UserActivityService } from './mock/user-activity.service';
import { OrdersChartService } from './mock/orders-chart.service';
import { ProfitChartService } from './mock/profit-chart.service';
import { TrafficListService } from './mock/traffic-list.service';
import { EarningService } from './mock/earning.service';
import { OrdersProfitChartService } from './mock/orders-profit-chart.service';
import { TrafficBarService } from './mock/traffic-bar.service';
import { ProfitBarAnimationChartService } from './mock/profit-bar-animation-chart.service';
import { TemperatureHumidityService } from './mock/temperature-humidity.service';
import { SolarService } from './mock/solar.service';
import { TrafficChartService } from './mock/traffic-chart.service';
import { StatsBarService } from './mock/stats-bar.service';
import { CountryOrderService } from './mock/country-order.service';
import { StatsProgressBarService } from './mock/stats-progress-bar.service';
import { VisitorsAnalyticsService } from './mock/visitors-analytics.service';
import { SecurityCamerasService } from './mock/security-cameras.service';
import { RippleService } from './utils/ripple.service';
import { ConfigService } from './services';
import { MockDataModule } from './mock/mock-data.module';
import { AuthGuard } from './guards/auth.guard';
//import { HttpErrorResponse } from '@angular/common/http';
//import { NbPasswordAuthStrategyOptions } from '../@auth/strategies/password/password-strategy-options';
//import { getDeepFromObject } from 'app/@auth/helpers';

const socialLinks = [
];


const authConfig = {
  name: 'email',
  delay: 0,
  //baseEndpoint: 'https://localhost:5001/v1',
  baseEndpoint: 'https://gwc-eagle-stag-euw-api.azurewebsites.net/v1',
 // baseEndpoint: 'https://gwc-eagle-prod-euw-api.azurewebsites.net/v1',
  token: {
    class: NbAuthJWTToken,  // Inject NbAuthJWTToken (instead of the default NbAuthSimpleToken) which is a wrapper class  around a value your API service returns.
    key: 'data.token', // this parameter tells where to look for the token
    // getter: (module: string, res: HttpResponse<Object>, options: NbPasswordAuthStrategyOptions) => getDeepFromObject(
    //   res.body,
    //   options.token.key,
    // ),
  },
  refreshToken: {
    endpoint: '/account/refresh-token',
    method: 'post',
    requireValidToken: false,
    redirect: {
      success: null,
      failure: null,
    },
    defaultErrors: ['Something went wrong, please try again.'],
    defaultMessages: ['Your token has been successfully refreshed.'],
  },
  login: {
    alwaysFail: false,
    endpoint: '/account/login',
    method: 'post',
    requireValidToken: true,
    redirect: {
      success: 'pages/dashboard', // redirect here after login success
      failure: null, // stay on the same page
    },
    defaultErrors: ['Login/Email combination is not correct, please try again.'],
    defaultMessages: ['You have been successfully logged in.'],
  },
  register: {
    alwaysFail: false,
    endpoint: '/account/register',
    method: 'post',
    requireValidToken: true,
    redirect: {
      success: 'pages/dashboard', // redirect here after registeration success
      failure: null,
    },
    defaultErrors: ['Something went wrong, please try again.'],
    defaultMessages: ['You have been successfully registered.'],
  },
  logout: {
    alwaysFail: false,
    endpoint: '/account/logout',
    method: 'delete',
    strategy: 'email',
    redirect: {
      success: '/auth/login',
      failure: '/auth/login',
    },
    defaultErrors: ['Something went wrong, please try again.'],
    defaultMessages: ['You have been successfully logged out.'],
  },
  requestPass: {
    endpoint: '/account/request-password',
    method: 'post',
    redirect: {
      success: '/',
      failure: null,
    },
    defaultErrors: ['Something went wrong, please try again.'],
    defaultMessages: ['Reset password instructions have been sent to your email.'],
  },
  resetPass: {
    endpoint: '/account/reset-password',
    method: 'post',
    redirect: {
      success: '/',
      failure: null,
    },
    resetPasswordTokenKey: 'reset_password_token',
    defaultErrors: ['Something went wrong, please try again.'],
    defaultMessages: ['Your password has been successfully changed.'],
  },
  errors: {
    key: 'errors',
    // getter: (module: string, res: HttpErrorResponse, options: NbPasswordAuthStrategyOptions) => getDeepFromObject(
    //   res.error,
    //   options.errors.key,
    //   options[module].defaultErrors,
    // ),
  },
  messages: {
    key: 'messages',
    // getter: (module: string, res: HttpResponse<Object>, options: NbPasswordAuthStrategyOptions) => getDeepFromObject(
    //   res.body,
    //   options.messages.key,
    //   options[module].defaultMessages,
    // ),
  },
  // validation: {
  //   password: {
  //     required?: boolean;
  //     minLength?: number | null;
  //     maxLength?: number | null;
  //     regexp?: string | null;
  //   },
  //   email?: {
  //     required?: boolean;
  //     regexp?: string | null;
  //   },
  //   fullName?: {
  //     required?: boolean;
  //     minLength?: number | null;
  //     maxLength?: number | null;
  //     regexp?: string | null;
  //   }
  // }
};

const DATA_SERVICES = [
  { provide: UserData, useClass: UserService },
  { provide: ElectricityData, useClass: ElectricityService },
  { provide: SmartTableData, useClass: SmartTableService },
  { provide: UserActivityData, useClass: UserActivityService },
  { provide: OrdersChartData, useClass: OrdersChartService },
  { provide: ProfitChartData, useClass: ProfitChartService },
  { provide: TrafficListData, useClass: TrafficListService },
  { provide: EarningData, useClass: EarningService },
  { provide: OrdersProfitChartData, useClass: OrdersProfitChartService },
  { provide: TrafficBarData, useClass: TrafficBarService },
  { provide: ProfitBarAnimationChartData, useClass: ProfitBarAnimationChartService },
  { provide: TemperatureHumidityData, useClass: TemperatureHumidityService },
  { provide: SolarData, useClass: SolarService },
  { provide: TrafficChartData, useClass: TrafficChartService },
  { provide: StatsBarData, useClass: StatsBarService },
  { provide: CountryOrderData, useClass: CountryOrderService },
  { provide: StatsProgressBarData, useClass: StatsProgressBarService },
  { provide: VisitorsAnalyticsData, useClass: VisitorsAnalyticsService },
  { provide: SecurityCamerasData, useClass: SecurityCamerasService },
  { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useExisting: RippleService },
];



export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...MockDataModule.forRoot().providers,
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({

    strategies: [
      // NbDummyAuthStrategy.setup({
      //   name: 'email',
      //   delay: 3000,
      // }),
      NbPasswordAuthStrategy.setup(authConfig),
    ],
    forms: {
      login: {
        redirectDelay: 0, // delay before redirect after a successful login, while success message is shown to the user
        //strategy: 'auth',  // strategy id key.
        rememberMe: false,   // whether to show or not the `rememberMe` checkbox
        showMessages: {     // show/not show success/error messages
          success: false,
          error: true,
        }, // social links at the bottom of a page
        socialLinks: socialLinks,
      },
      register: {
        socialLinks: socialLinks,
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
  LayoutService,
  PlayerService,
  SeoService,
  StateService,
  ConfigService,
  AuthGuard
];

@NgModule({
  imports: [
    CommonModule,
    NbAuthModule
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {

    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {

    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
