
import { Component, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';

import { NbAuthService } from '../services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'nb-auth',
  styleUrls: ['./auth.component.scss'],
  template: `
  <div id="bkg-img">
    <nb-layout>
      <nb-layout-column>
      <nb-card>
      <!-- <nb-card-header>
      <div class="row">
          <div class="col-12 col-xs-12">
            <img src="../../../assets/images/gwc_logo_white_bg.png" height="70"/>
          </div>
          <div class="col-12 col-xs-12">
            <h1>EAGLE PORTAL</h1>
          </div>
      </div>
      </nb-card-header> -->
      <nb-card-body>
          <nb-auth-block>
            <router-outlet></router-outlet>
          </nb-auth-block>
          </nb-card-body>
          </nb-card> 
      <!-- <nb-card>
        <nb-card-header>
          <nav class="navigation">
            <a href="#" (click)="back()" class="link back-link" aria-label="Back">
              <nb-icon icon="arrow-back"></nb-icon>
            </a>
          </nav>
        </nb-card-header>
        <nb-card-body>
        <nb-auth-block>
        <router-outlet></router-outlet>
      </nb-auth-block>
        </nb-card-body>
    </nb-card>  -->
    
      </nb-layout-column>
    </nb-layout>
    </div>
  `,
})
export class NbAuthComponent implements OnDestroy {

  private destroy$ = new Subject<void>();

  subscription: any;

  authenticated: boolean = false;
  token: string = '';

  // showcase of how to use the onAuthenticationChange method
  constructor(protected auth: NbAuthService, protected location: Location) {

    this.subscription = auth.onAuthenticationChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe((authenticated: boolean) => {
        this.authenticated = authenticated;
      });
  }

  back() {
    this.location.back();
    return false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
