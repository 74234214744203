/**
 * @license
 * Copyright LEDD. All Rights Reserved.
 *
 */
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json` using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  RequireHttps: false,
  ApplicationConfig: {
    // 'BASE_URL': 'https://localhost:5001',
    //'BASE_URL': 'https://gwc-eagle-dev-euw-api.azurewebsites.net',
    'BASE_URL': 'https://gwc-eagle-stag-euw-api.azurewebsites.net',
    // 'RESOURCE_SERVER': 'https://localhost:9003',
    // 'ISSUER_URI': 'https://localhost:5000',
    // 'REDIRECT_URI': 'https://localhost:4200'
    'RESOURCE_SERVER': '',
    'ISSUER_URI': '',
    'REDIRECT_URI': ''
  }
};
