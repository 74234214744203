import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      <b><a href="https://gwclogistics.com" target="_blank" style=" text-decoration: none">©{{date}} GWC | All rights reserved | Terms and Conditions</a></b>
    </span>
  `,
})
export class FooterComponent {
  date = new Date().getFullYear();
}
