
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NB_AUTH_OPTIONS } from '../../auth.options';
import { getDeepFromObject } from '../../helpers';
import { NbAuthService } from '../../services/auth.service';
import { NbAuthResult } from '../../services/auth-result';

@Component({
  selector: 'nb-logout',
  templateUrl: './logout.component.html',
})
export class NbLogoutComponent implements OnInit {

  redirectDelay: number = 0;
  strategy: string = '';
  //cart: Cart;
  
  constructor(protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected router: Router) {
    this.redirectDelay = this.getConfigValue('forms.logout.redirectDelay');
    this.strategy = this.getConfigValue('forms.logout.strategy');
  }

  ngOnInit(): void {
    this.logout(this.strategy);
  }

  logout(strategy: string): void {
    console.log('Logout component called with strategy', strategy);
    this.service.logout(strategy).subscribe((result: NbAuthResult) => {

      // this.cartService.clear().subscribe(result =>{
      //   console.log("Cart Items Emptied");
      // });
      // this.requestCartService.cartChange().subscribe((cart: Cart) => {
      //   console.log('Current user Cart Obtained');
      //   //console.log('Current user Cart Obtained : ' + JSON.stringify(cart));
      //   this.cart = cart;
      //   this.itemCount = this.cart == null ? "0" : (this.cart.shipments.length + this.cart.asns.length).toString();
      // });

      //console.log('Auth result', result);
      const redirect = result.getRedirect();
      //const redirect = '/';
      console.log('redirecting after logout to', redirect);
      if (redirect) {
        setTimeout(() => {
          return this.router.navigateByUrl(redirect);
        }, this.redirectDelay);
      }
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
